const properties = {
  PORT: process.env.PORT || 3001,
  REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || "development",
  REACT_APP_SERVER_URL: "https://api.klubstack.com",
  REACT_APP_CLIENT_URL: "https://app.klubstack.com",
  HTTPS: process.env.REACT_APP_ENVIRONMENT === "production" ? true : false,
  REACT_APP_OAUTH_CLIENT_ID: "1001488136915-g21tr2naanm4505fms3g6o0pgqebp65b.apps.googleusercontent.com",
  REACT_APP_OAUTH_CLIENT_KEY: "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",
  REACT_APP_TINYMCE_API_KEY: "wmwvp6jr4mgn50wcafq0n31gfckzz5m8259s13vfbdo9iklc"
};

export default properties;
