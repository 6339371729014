import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import Button from "../../../Componenets/Button";
import DraftEditor from "../../../Componenets/DraftEditor";
import { Editor } from "@tinymce/tinymce-react";
import properties from "../../../config/properties";
const MailboxesSetting = ({
  loader,
  editorState,
  handleCreateSignature,
  setEditorState,
  editorInitialValue,
}) => {
  const handleEditorChange = (content, editor) => {
    setEditorState(content);
  };
  return (
    <div className="bg-white">
      <h1 className="primary-heading-color  font-semibold border-b border-border-gray p-4">
        Email signature
      </h1>

      <div className="p-4">
        <div className="text-editor text-[#5d6a7e] text-[14px]  relative">
          {/* <DraftEditor
            editorState={editorState}
            setEditorState={setEditorState}
          ></DraftEditor> */}

          <Editor
            initialValue={editorInitialValue}
            apiKey={properties.REACT_APP_TINYMCE_API_KEY}
            onEditorChange={handleEditorChange}
            init={{
              plugins:
                " anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount ",
              toolbar:
                "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
              tinycomments_mode: "embedded",
              tinycomments_author: "Author name",
              mergetags_list: [
                { value: "First.Name", title: "First Name" },
                { value: "Email", title: "Email" },
              ],
            }}
          />
        </div>
      </div>

      <div className="px-4 pb-4">
        <LoadingButton
          className=" bg-[#583cea] btn"
          loading={loader}
          onClick={handleCreateSignature}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
        >
          Save
        </LoadingButton>
        {/* <Button
          loading={loader}
          disabled={loader}
          onClick={handleCreateSignature}
          tittle={"Save"}
        ></Button> */}
      </div>
    </div>
  );
};

export default MailboxesSetting;
